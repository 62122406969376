exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/Article/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-index-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---src-pages-contact-us-a-js": () => import("./../../../src/pages/contact-us-a.js" /* webpackChunkName: "component---src-pages-contact-us-a-js" */),
  "component---src-pages-contact-us-e-js": () => import("./../../../src/pages/contact-us-e.js" /* webpackChunkName: "component---src-pages-contact-us-e-js" */),
  "component---src-pages-contact-us-r-js": () => import("./../../../src/pages/contact-us-r.js" /* webpackChunkName: "component---src-pages-contact-us-r-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-ricardo-js": () => import("./../../../src/pages/ricardo.js" /* webpackChunkName: "component---src-pages-ricardo-js" */)
}

